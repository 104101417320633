import { ref, computed } from "vue";
import { useStore } from "vuex";
import responseHandler from "@/utils/responseHandler.js";
import api from "@/utils/api.js";
import { getItem } from "@/utils/storage.js";

export function useFundingProvider() {
  const store = useStore();

  const user = ref(getItem("org_details"));
  const fundPayload = ref(getItem("fundPayload"));
  const loading = ref(false);

  const currency = computed(() => store.state.organisationModule.currency);
  const currencies = ref(null);
  const formatCurrencies = computed(() => {
    const formattedCurrencies = [];
    for (const provider in currencies.value) {
      for (const curr in currencies.value[provider]) {
        formattedCurrencies.push({
          name: currencies.value[provider][curr],
          currency: curr,
          provider,
        });
      }
    }
    return formattedCurrencies;
  });

  const providerFunding = async (provider, addCard) => {
    loading.value = true;
    const payload = fundPayload.value || getItem("fundPayload");
    const data = {
      amount: payload.amount,
      currency: currency.value,
      customer: {
        name: user.value.name,
        email: user.value.email,
      },
      meta: {
        // tokens: Number(payload.tokens),
        entity: "user",
        add_card: addCard,
      },
      paymentReference: "",
      redirect_url: window.location.origin + window.location.pathname,
    };
    try {
      if (!addCard) {
        delete data.meta.add_card;
      }
      const response = await api.fundWithProvider({
        data,
        type: provider,
      });
      window.location =
        provider === "flutterwave"
          ? response.data.data.link
          : response.data.data.url;
      loading.value = false;
    } catch (error) {
      loading.value = false;
      responseHandler.handleError(error);
    }
  };
  const getCurrencies = async () => {
    try {
      const response = await api.getCurrencies();
      currencies.value = response.data.jsonData;
    } catch (error) {
      responseHandler.handleError(error);
    }
  };

  return {
    providerFunding,
    loading,
    getCurrencies,
    currencies,
    formatCurrencies,
  };
}
