<template>
  <div id="card-options-form" class="tw-mt-8">
    <div v-for="(card, index) in entityCard" :key="index">
      <div
        class="tw-grid tw-grid-cols-4 tw-bg-gray-lightest tw-rounded tw-cursor-pointer tw-p-6 tw-mb-4"
        @click="storeData(card)"
      >
        <div class="tw-flex tw-col-start-1 tw-col-end-4">
          <img
            v-if="card.type === 'VISA'"
            src="@/assets/img/visa.svg"
            alt="padlock"
          />
          <img
            v-if="card.type === 'MASTERCARD'"
            src="@/assets/img/mastercard.svg"
            alt="padlock"
          />
          <div class="tw-ml-4">
            <h3 class="tw-font-bold">****{{ card.last_four }}</h3>
            <p class="tw-text-black-light tw-text-sm">
              Expires {{ card.expiry }}
            </p>
          </div>
        </div>
        <img
          class="tw-justify-self-end tw-self-center"
          src="@/assets/img/chevron-right.svg"
          alt="chevron"
        />
      </div>
    </div>
    <div
      class="tw-flex tw-justify-between tw-items-center tw-bg-gray-lightest tw-rounded tw-cursor-pointer tw-p-6"
      @click="storeData"
    >
      <h3 class="tw-font-bold">Use a New Card</h3>
      <img src="@/assets/img/chevron-right.svg" alt="chevron" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CardOptionsForm",

  emits: ["storeData"],

  computed: {
    ...mapState({
      entityCard: (state) => state.organisationModule.entityCard,
    }),
  },

  methods: {
    ...mapActions("organisationModule", ["setCardDetails"]),

    storeData(card) {
      if (card.last_four) {
        this.setCardDetails(card);
        this.$emit("storeData", "storedCard");
      } else {
        this.$emit("storeData", "newCard");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
