<template>
	<div id="dedicated-virtual-account-form">
		<div class="tw-flex tw-items-center tw-bg-gray-lightest tw-p-4 tw-mt-8">
			<img src="@/assets/img/blue-bank.svg" alt="bank" class="tw-mr-2" />
			<h3 class="tw-font-semibold">{{ currency }} virtual account</h3>
		</div>
		<div class="tw-border tw-p-4">
			<p class="tw-text-gray tw-text-sm tw-mb-1">Account Name</p>
			<div class="tw-flex tw-items-center tw-justify-between">
				<input
					class="tw-w-full tw-font-semibold tw-break-words tw-border-0"
					type="text"
					readonly
					value="GETEQUITY LIMITED"
					@focus="$event.target.select()"
					ref="accountName"
				/>
				<span
					class="tw-rounded tw-bg-blue-bg200 tw-text-secondary tw-border tw-cursor-pointer tw-py-1 tw-px-2 tw-mb-6 sm:tw-mb-0"
					@click="copyText('account name')"
				>
					Copy
				</span>
			</div>
		</div>
		<div class="tw-border tw-p-4">
			<p class="tw-text-gray tw-text-sm tw-mb-1">Account Number</p>
			<div class="tw-flex tw-items-center tw-justify-between">
				<input
					class="tw-w-full tw-font-semibold tw-break-words tw-border-0"
					type="text"
					readonly
					:value="virtualAccount?.account_number"
					@focus="$event.target.select()"
					ref="accountNumber"
				/>
				<span
					class="tw-rounded tw-bg-blue-bg200 tw-text-secondary tw-border tw-cursor-pointer tw-py-1 tw-px-2 tw-mb-6 sm:tw-mb-0"
					@click="copyText('account number')"
				>
					Copy
				</span>
			</div>
		</div>
		<div class="tw-border tw-p-4">
			<p class="tw-text-gray tw-text-sm tw-mb-1">Bank Name</p>
			<div class="tw-flex tw-items-center tw-justify-between">
				<input
					class="tw-w-full tw-font-semibold tw-break-words tw-border-0"
					type="text"
					readonly
					:value="capitalize(virtualAccount?.bank_name)"
					@focus="$event.target.select()"
					ref="bankName"
				/>
				<span
					class="tw-rounded tw-bg-blue-bg200 tw-text-secondary tw-border tw-cursor-pointer tw-py-1 tw-px-2 tw-mb-6 sm:tw-mb-0"
					@click="copyText('bank name')"
				>
					Copy
				</span>
			</div>
		</div>
		<div class="tw-border tw-p-4">
			<p class="tw-text-gray tw-text-sm tw-mb-1">Bank Code</p>
			<div class="tw-flex tw-items-center tw-justify-between">
				<input
					class="tw-w-full tw-font-semibold tw-break-words tw-border-0"
					type="text"
					readonly
					:value="virtualAccount?.bank_code"
					@focus="$event.target.select()"
					ref="bankCode"
				/>
				<span
					class="tw-rounded tw-bg-blue-bg200 tw-text-secondary tw-border tw-cursor-pointer tw-py-1 tw-px-2 tw-mb-6 sm:tw-mb-0"
					@click="copyText('bank code')"
				>
					Copy
				</span>
			</div>
		</div>
		<div v-if="currency !== 'NGN'" class="tw-border tw-p-4">
			<p class="tw-text-gray tw-text-sm tw-mb-1">Address</p>
			<div class="tw-flex tw-items-center tw-justify-between">
				<textarea
					class="tw-w-full tw-font-semibold tw-break-words tw-border-0 tw-resize-none"
					type="text"
					readonly
					:value="virtualAccount?.address"
					@focus="$event.target.select()"
					ref="address"
				/>
				<span
					class="tw-rounded tw-bg-blue-bg200 tw-text-secondary tw-border tw-cursor-pointer tw-py-1 tw-px-2 tw-mb-6 sm:tw-mb-0"
					@click="copyText('address')"
				>
					Copy
				</span>
			</div>
		</div>
		<!-- <div
      v-if="currency !== 'USD'"
      class="tw-grid tw-grid-cols-3 tw-items-center tw-gap-2 tw-border tw-p-4"
    >
      <div
        class="tw-col-start-1 tw-col-end-3 tw-flex tw-justify-between tw-items-center"
      >
        <p class="tw-text-gray tw-text-sm tw-whitespace-nowrap">FX Rate</p>
        <div>
          <span>$ </span>
          <input
            type="text"
            class="tw-w-28 sm:tw-w-40 tw-rounded tw-pl-4 tw-py-4"
            value="1"
            disabled
          />
        </div>
      </div>
      <div class="tw-flex tw-items-center">
        <span class="tw-mr-1">=</span>
        <span v-if="!fxLoading">
          {{ formatAmountToDollar(exchangeRate, 2, currency) }}
        </span>
        <img
          v-if="fxLoading"
          class="tw-mx-auto"
          src="@/assets/img/blue-spinner.svg"
          alt="animated spinner"
        />
      </div>
    </div> -->
		<div class="tw-flex tw-bg-orange-lightest tw-rounded tw-p-6 tw-mt-8">
			<!-- <img
        class="tw-w-8 tw-mr-2"
        src="@/assets/img/orange-alert.svg"
        alt="orange alert"
      /> -->
			<div>
				<p
					class="tw-text-sm tw-text-purple-light tw-mb-2 tw-uppercase !tw-font-CircularStd-medium"
				>
					Payment Instructions:
				</p>
				<p class="tw-text-orange tw-text-sm">
					Kindly transfer the exact amount of
					<b>{{
						formatAmountToDollar(fundDetails.totalAmount, 2, currency)
					}}</b>
					to the bank details above, and ensure to use the reference code
					<span class="tw-underline !tw-font-CircularStd-medium"
						>({{ virtualAccount.customer_reference }})</span
					>
					in the description.
					<span class="tw-text-red-error !tw-font-CircularStd-medium"
						>Failure to include the reference code may cause delay in processing
						your transaction.</span
					>
				</p>
				<p class="tw-text-orange tw-text-sm tw-mt-2">
					Please note that deposits may take 1 - 48 hours to reflect. Only click
					the button after a successful transaction.
				</p>
			</div>
			<!-- <p v-if="currency !== 'NGN'" class="tw-text-orange tw-text-sm tw-py-4">
        How does local bank transfer work?
      </p> -->
		</div>
    <Btn
      class="tw-mt-8"
      title="Done"
      bgColour="blue"
      @click="$emit('close', 'Fund Wallet')"
    />
	</div>
</template>

<script>
import Btn from "@/components/general/BtnComponent.vue";
import { capitalize, formatAmountToDollar } from "@/utils/helpers";
import { getItem } from "@/utils/storage.js";
import { mapActions, mapState } from "vuex";

export default {
	name: "DedicatedVirtualAccountForm",
  components: {
    Btn
  },

	data() {
		return {
			fxLoading: false,
			userDetails: {},
			name: "",
		};
	},
	computed: {
		...mapState({
			virtualAccount: (state) => state.organisationModule.virtualAccount,
			currency: (state) => state.organisationModule.currency,
			exchangeRate: (state) => state.organisationModule.exchangeRate,
		}),
		fundDetails: () => getItem("fundPayload"),
	},

	mounted() {
		this.getRate();
		this.userDetails = getItem("org_details");
		this.name = `${capitalize(this.userDetails.name)}`;
	},

	methods: {
		...mapActions(["showToast"]),
		...mapActions("organisationModule", ["getExchangeRate"]),

		capitalize,
		formatAmountToDollar,

		async getRate() {
			this.fxLoading = true;
			const exchangeQuery = { from: "USD", to: this.currency };
			await this.getExchangeRate(exchangeQuery);
			this.fxLoading = false;
		},

		copyText(text) {
			let message = "";
			switch (text) {
				case "account number":
					this.$refs.accountNumber.focus();
					message = "Account number";
					break;
				case "account name":
					this.$refs.accountName.focus();
					message = "Account name";
					break;
				case "bank name":
					this.$refs.bankName.focus();
					message = "Bank name";
					break;
				case "bank code":
					this.$refs.bankCode.focus();
					message = "Bank code";
					break;
				case "address":
					this.$refs.address.focus();
					message = "Address";
					break;

				default:
					break;
			}

			// TODO: Look for an alternative to execCommand
			document.execCommand("copy");
			this.showToast({
				description: `${message} copied`,
				display: true,
				type: "success",
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
