<template>
	<div class="tw-w-full tw-mt-8">
		<template v-if="swapCurrency && fundOptions.includes('Wallet')">
			<form @submit.prevent="fundAmount">
				<div class="tw-grid tw-grid-cols-2">
					<div>
						<label
							class="tw-text-secondary !tw-font-CircularStd-medium tw-leading-[26px] tw-tracking-[-0.2px] tw-mb-1 tw-block"
							>Enter amount ({{ swapCurrency }})</label
						>
						<p
							class="tw-text-sm tw-leading-[21px] tw-text-purple-light"
							v-if="currencyRate"
						>
							{{ currency }} equivalent:
							{{ formatAmountToDollar(swapConversion, 2, currency) }}
						</p>
					</div>
					<input
						type="number"
						name="amount"
            :max="currencyBalance"
						:disabled="loading"
						id="amount"
						class="tw-w-full tw-rounded-lg tw-border tw-border-gray-light tw-h-14 tw-pl-4 tw-mb-4"
						v-model="payload.amount"
					/>
				</div>
				<div
					class="tw-mt-8 tw-rounded tw-h-[45px] tw-bg-gray-light tw-py-3"
					v-if="currencyRate && !loading"
				>
					<p class="tw-text-sm tw-text-purple-light tw-text-center">
						Current FX rate:
						<span class="tw-text-secondary !tw-font-CircularStd-medium">
							{{ formatAmountToDollar(1, 2, swapCurrency) }} =
							{{ formatAmountToDollar(currencyRate, 4, currency) }}</span
						>
					</p>
				</div>
				<div class="tw-mt-8 tw-ml-auto tw-w-[171px]">
					<Btn
						:loading="loading"
						title="Continue"
						:disabled="loading || !payload.amount"
					/>
				</div>
			</form>
		</template>
		<template v-else>
			<div class="tw-mb-8">
				<h2
					class="tw-text-secondary !tw-font-CircularStd-medium tw-font-lg tw-leading-[28.8px]"
				>
					You are funding
				</h2>
				<div
					class="tw-pb-4 tw-bg-gray-lightest tw-rounded tw-cursor-pointer tw-p-4 tw-mb-8"
				>
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">Wallet Type</p>
					<div class="tw-flex tw-gap-x-2">
						<CurrencyFlag :currency="currency" />
						<h3 class="tw-text-sm tw-font-bold">{{ currency }} Wallet</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="fundAmount">
				<h2 class="tw-font-primary tw-font-bold tw-text-lg tw-mb-3">
					How much do you want to fund?
				</h2>
				<label
					class="tw-text-secondary !tw-font-CircularStd-medium tw-leading-[26px] tw-tracking-[-0.2px] tw-mb-2 tw-block"
					>Enter Amount ({{ currency }})</label
				>
				<input
					type="number"
					:min="minimumDeposit"
					name="amount"
					id="amount"
					class="tw-w-full tw-rounded-lg tw-border tw-border-gray-light tw-h-14 tw-pl-4 tw-mb-4"
					v-model="payload.amount"
				/>
				<div class="tw-mt-8 tw-ml-auto tw-w-[171px]">
					<Btn
						:loading="loading"
						title="Continue"
						:disabled="loading || !payload.amount"
					/>
				</div>
			</form>
		</template>
	</div>
</template>

<script>
import Btn from "@/components/general/BtnComponent.vue";
import NumberInput from "@/components/wallet/NumberInput.vue";
import { formatAmountToDollar } from "@/utils/helpers.js";
import fundPercent from "@/utils/js/fundPercent.js";
import { getItem, setItem } from "@/utils/storage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import CurrencyFlag from "../CurrencyFlag.vue";

export default {
	name: "FundAmountForm",

	emits: ["storeData"],

	components: {
		NumberInput,
		Btn,
		CurrencyFlag,
	},

	props: {
		fundOptions: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			amountValid: false,
			validInput: {
				amount: false,
				dollarEquivalent: false,
			},
      fundPercent,
			payload: { amount: "" },
			dollarEquivalent: 0,
			minAmount: 0,
			transactionFee: 0,
			loading: false,
		};
	},
	watch: {
		"payload.amount"(val) {
			if (val) {
				this.calculateFee();
			}
		},
	},

	inject: ["swapCurrency"],

	computed: {
		...mapState({
			fundMethod: (state) => state.organisationModule.fundMethod,
			currency: (state) => state.organisationModule.currency,
			exchangeRate: (state) => state.organisationModule.exchangeRate,
		}),
		...mapGetters("organisationModule", ["minimumDeposit", "currencyRate", 'walletBalance']),
		swapConversion() {
			if (this.swapCurrency) {
				return this.payload.amount * Number(this.currencyRate);
			}
			return 0;
		},
    currencyBalance() {
      if(this.swapCurrency) {
        return this.walletBalance.find((wallet) => wallet.currency === this.swapCurrency)?.balance;
      }
      return null
    }
	},
	mounted() {
		this.getMinimumFunding();
		if (this.fundOptions.includes("Wallet")) {
			this.getRate();
		}
		const details = getItem("fundPayload");
		const amount = details?.amount || 0;
		this.payload.amount = amount;
	},

	methods: {
		...mapActions("organisationModule", [
			"getMinimumFunding",
			"convertCurrency",
		]),
		formatAmountToDollar,
		async getRate() {
			const exchangeQuery = {
				from: this.swapCurrency,
				to: this.currency,
				amount: this.payload.amount || 1,
			};
			this.loading = true;
			await this.convertCurrency(exchangeQuery);
			this.loading = false;
		},

		calculateFee() {
			let payload = null;
			let percent;
			switch (this.currency) {
				case "NGN":
					percent = this.fundPercent.NGN;
					switch (this.fundOptions) {
						case "Fund with Fintech Apps":
							this.transactionFee =
								this.fundPercent.THEPEER * this.payload.amount + 25;
							break;
						case "Crypto":
							this.transactionFee =
								(this.payload.amount / 100) *
								this.fundPercent.FLUIDCOINS *
								100;
							break;
						case "USD Wallet":
							this.transactionFee = 0;
							break;
						case "NGN Wallet":
							this.transactionFee = 0;
							break;
						case "KES Wallet":
							this.transactionFee = 0;
							break;

						default:
							this.transactionFee = percent * this.payload?.amount;
							this.transactionFee =
								this.transactionFee > 2000 ? 2000 : this.transactionFee;
							break;
					}
					payload = {
						tokens: this.payload?.amount,
						amount: this.payload.amount,
						totalAmount: this.payload.amount + this.transactionFee,
					};
					setItem("fundPayload", payload);
					break;
				default:
					percent = this.fundPercent.USD;
					switch (this.fundOptions) {
						case "Fund with Fintech Apps":
							this.transactionFee =
								this.fundPercent.THEPEER * this.payload.amount + 25;
							break;
						case "Crypto":
							this.transactionFee =
								(this.payload.amount / 100) *
								this.fundPercent.FLUIDCOINS *
								100;
							break;
						case "USD Wallet":
							this.transactionFee = 0;
							break;
						case "NGN Wallet":
							this.transactionFee = 0;
							break;
						case "KES Wallet":
							this.transactionFee = 0;
							break;

						default:
							this.transactionFee = percent * this.payload?.amount;
							break;
					}
					payload = {
						tokens: this.payload?.amount,
						amount: this.payload.amount,
						totalAmount: this.payload.amount + this.transactionFee,
					};
					setItem("fundPayload", payload);
					break;
			}
		},

		fundAmount() {
			// this.loading = true;
			switch (this.fundOptions) {
				case "Bank or Card":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "Mobile Money":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "Fund with bank transfer":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "Fund with debit card":
					this.$emit("storeData", "fundAmountForm");
					// this.flutterwaveFunding();
					break;
				case "":
					this.$emit("storeData", "fundProvider");
					// this.flutterwaveFunding();
					break;
				case "Fund with Fintech Apps":
					// this.thepeerFunding();
					this.$emit("storeData", "fundAmountForm");
					break;
				case "KES Wallet":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "USD Wallet":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "NGN Wallet":
					this.$emit("storeData", "fundAmountForm");
					break;
				case "Crypto":
					// this.fluidCoinFunding();
					this.$emit("storeData", "fundAmountForm");
					break;

				default:
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
