<template>
	<div>
		<div v-if="!smallLoading">
			<FundOptionsCard
				v-if="currency === 'NGN' || currency === 'USD'"
				type="Bank or Card"
				@clicked="clicked"
			/>
			<FundOptionsCard
				v-if="currency === 'KES'"
				type="Mobile Money"
				@clicked="clicked"
			/>
			<FundOptionsCard
				v-if="currency === 'NGN' || currency === 'USD'"
				type="Fund with bank transfer"
				@clicked="clicked"
			/>
			<!-- <FundOptionsCard type="Fund with debit card" @clicked="clicked" /> -->
			<!-- <FundOptionsCard type="Fund with Fintech Apps" @clicked="clicked" /> -->
			<!-- <FundOptionsCard
				v-if="currency === 'USD'"
				type="Crypto"
				@clicked="clicked"
			/> -->
			<FundOptionsCard type="Another wallet" @clicked="clicked" />
		</div>
		<SmallLoader v-if="smallLoading" />
	</div>
</template>

<script setup>
import FundOptionsCard from "@/components/wallet/FundOptionsCard";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const emit = defineEmits(["options"]);
defineProps({
	// rate: { type: Number, default: () => 0, required: true },
	isAllowed: { type: Boolean, default: () => false, required: true },
});

const fundMethod = ref("");

const currency = computed(() => store.getters["organisationModule/currency"]);
const smallLoading = computed(() => store.state.smallLoading);

const clicked = async (options) => {
	if (options === "Bank or Card" || options === "Fund with debit card") {
		fundMethod.value = "fiat";
	} else if (options === "Crypto") {
		fundMethod.value = "crypto";
	} else if (options === "Another wallet") {
		fundMethod.value = "wallet";
	} else if (options === "Fund with bank transfer") {
		await store.dispatch("organisationModule/generateVirtualAccount", {
			currency: currency.value,
			"provider": "squad"
		});
	}
	store.dispatch("organisationModule/setFundMethod", fundMethod.value);
	emit("options", options);
};
</script>

<style lang="scss" scoped></style>
