<template>
  <div id="select-currency-form">
    <form class="tw-w-full tw-mt-8" @submit.prevent="storeData">
      <SelectInput
        label="Currency"
        defaltOption="Select currency"
        name="currency"
        id="currency"
        :list="fiatCurrencyList"
        @set="setCurrencyData"
      />
      <!-- <Dropdown
        name="currency"
        label="Select currency"
        v-bind="{
          isObject: true,
          objectValue: { label: 'name', value: 'currency' },
        }"
        :validate="true"
        @input-changed="setCurrency"
        :options="formatCurrencies"
      /> -->
      <Btn class="tw-mt-8" :loading="loading" title="Continue" />
    </form>
  </div>
</template>

<script setup>
  import Dropdown from "@/components/general/Dropdown.vue";
  import Btn from "@/components/general/BtnComponent.vue";
  import { getItem } from "@/utils/storage.js";
  import { useStore } from "vuex";
  import { computed, onMounted, ref } from "vue";
  import { useFundingProvider } from "@/composables/fundingProvider";
  import SelectInput from "@/components/general/SelectInput.vue";

  const { getCurrencies, formatCurrencies } = useFundingProvider();
  const emits = defineEmits(["storeData"]);
  const store = useStore();

  const showTooltip = ref(false);
  const currency = ref("");
  const userDetails = ref({});
  const fiatCurrencyList = ref([
    "🇳🇬 Nigerian Naira (NGN)",
    "🇺🇸 US Dollars (USD)",
    "🇪🇺 Euro (EUR)",
    "🇬🇧 Pounds (GBP)",
    "🇬🇭 Ghanaian Cedi (GHS)",
    "🇰🇪 Kenyan Shilling (KES)",
    "🇷🇼 Rwandan Franc (RWF)",
    "🇺🇬 Ugandan Shilling (UGX)",
    "🇹🇿 Tanzanian Shilling (TZS)",
    "🇸🇱 Sierra Leonean Leone (SLL)",
    "🇿🇼 Zimbabwean Kwacha (ZMW)",
    "🇿🇦 South African Rand (ZAR)",
    "🇪🇹 Ethiopian Birr (ETB)",
    "Central African Franc (XAF)",
    "West African CFA Franc (XOF)",
  ]);
  const virtualAccount = computed(
    () => store.state.organisationModule.virtualAccount
  );
  const loading = computed(() => store.state.smallLoading);
  formatCurrencies;

  const storeData = async () => {
    store.dispatch("organisationModule/setCurrency", currency.value);
    const exchangeQuery = { from: "USD", to: currency.value };
    const response = await store.dispatch(
      "organisationModule/getExchangeRate",
      exchangeQuery
    );
    if (response) {
      emits("storeData", "selectCurrencyForm");
    }
  };

  const gotoVirtualAccountForm = () => {
    store.dispatch("organisationModule/setCurrency", currency.value);
    emits("storeData", "selectCurrencyForm");
  };

  const setCurrency = ({ name, value }) => {
    currency.value = value;
  };

  const setCurrencyData = (curr) => {
    if (curr) {
      let abb = curr.split("(")[1].split(")")[0];
      currency.value = abb;
    }
  };

  onMounted(() => {
    userDetails.value = getItem("org_details");
    getCurrencies();
  });
</script>

<style lang="scss" scoped>
  #select-currency-form {
    .status {
      width: 5rem;
    }
  }
</style>
