<template>
	<div>
		<form @submit.prevent="fundRequest" class="tw-w-full tw-mt-8">
			<div
				class="tw-bg-gray-lightest tw-rounded tw-cursor-pointer tw-p-6 tw-my-8"
			>
				<div class="tw-border-b tw-pb-4">
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">
						You are {{ swapCurrency ? "converting" : "funding" }}
					</p>
					<h3 class="tw-text-sm tw-font-bold" v-if="swapCurrency">
						{{ formatAmountToDollar(fundPayload.amount, 2, swapCurrency) }}
					</h3>
					<h3 class="tw-text-sm tw-font-bold" v-else>
						{{ currency }} {{ convertToLocale(Number(fundPayload.amount)) }}
					</h3>
				</div>
				<div
					v-if="swapCurrency"
					class="tw-pb-4 tw-mt-4"
					:class="[fundOptions ? 'tw-border-b' : null]"
				>
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">To</p>
					<h3 class="tw-text-sm tw-font-bold">
						{{ currency }}
					</h3>
				</div>
				<div
					v-else
					class="tw-pb-4 tw-mt-4"
					:class="[fundOptions ? 'tw-border-b' : null]"
				>
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">Transaction Fee</p>
					<h3 class="tw-text-sm tw-font-bold">
						{{ currency }} {{ convertToLocale(Number(transactionFee)) }}
					</h3>
				</div>
				<div class="tw-mt-4" v-if="swapCurrency">
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">Rate</p>
					<h3 class="tw-text-sm tw-font-bold">
						1 {{ swapCurrency }} =
						{{ formatAmountToDollar(currencyRate, 4, currency) }}
					</h3>
				</div>
				<div class="tw-mt-4" v-else-if="fundOptions">
					<p class="tw-text-purple-light tw-text-sm tw-mb-1">via</p>
					<h3 class="tw-text-sm tw-font-bold">
						{{ fundingMeans }}
					</h3>
				</div>
			</div>
			<div class="tw-mb-12">
				<p
					class="tw-text-sm tw-text-purple-light tw-text-center"
					v-if="swapCurrency"
				>
					You will get
				</p>
				<p class="tw-text-sm tw-text-purple-light tw-text-center" v-else>
					Total Amount to Pay
				</p>
				<h3 class="tw-text-lg tw-font-bold tw-text-center" v-if="swapCurrency">
					{{ formatAmountToDollar(swapConversion, 2, currency) }}
				</h3>
				<h3 class="tw-text-lg tw-font-bold tw-text-center" v-else>
					{{ currency }}
					{{ convertToLocale(Number(fundPayload.amount + transactionFee)) }}
				</h3>
			</div>
			<CustomButton :loading="loading" title="Fund Account" />
		</form>
	</div>
</template>

<script>
import CustomButton from "@/components/general/BtnComponent";
import api from "@/utils/api.js";
import { convertToLocale, formatAmountToDollar } from "@/utils/helpers.js";
import fundPercent from "@/utils/js/fundPercent.js";
import responseHandler from "@/utils/responseHandler.js";
import { getItem, setItem } from "@/utils/storage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
	name: "FundConfirmationForm",

	emits: ["storeData", "close"],

	components: {
		CustomButton,
	},

	props: {
		fundOptions: { type: String, default: () => "", required: true },
	},

	data() {
		return {
			user: getItem("org_details"),
			fundPayload: {},
			transactionFee: 0,
			fundPercent,
			customizations: {
				title: "GetEquity Payments",
				description: "Purchase of GUSD from GetEquity",
			},
			loading: false,
		};
	},

	inject: ["swapCurrency"],

	computed: {
		...mapState({
			currency: (state) => state.organisationModule.currency,
			cardDetails: (state) => state.organisationModule.cardDetails,
		}),
		...mapGetters(["smallLoading"]),
		...mapGetters("organisationModule", ["currencyRate"]),

		fundingMeans() {
			let fundingMeans = null;
			switch (this.fundOptions) {
				case "Fund with debit card":
					fundingMeans = "Debit/credit card";
					break;
				case "Bank or Card":
					fundingMeans = "Bank or Card";
					break;
				case "Mobile Money":
					fundingMeans = "Mobile Money";
					break;
				case "Fund with bank transfer":
					fundingMeans = "Bank transfer";
					break;
				case "Fund with debit card":
					fundingMeans = "";
					break;
				case "Crypto":
					fundingMeans = "Crypto";
					break;
				case "Fund with Fintech Apps":
					fundingMeans = "ThePeer";
					break;
			}
			return fundingMeans;
		},
		swapConversion() {
			if (this.swapCurrency) {
				return this.fundPayload.amount * Number(this.currencyRate);
			}
			return 0;
		},
	},

	mounted() {
		this.calculateFee();
	},

	methods: {
		...mapActions("organisationModule", ["getWalletBalance", "convertWallet"]),
		...mapActions(["showToast"]),

		formatAmountToDollar,
		convertToLocale,

		calculateFee() {
			const details = getItem("fundPayload");
			let percent;
			switch (this.currency) {
				case "NGN":
					percent =
						this.fundingMeans === "Bank transfer"
							? fundPercent.HABARI
							: fundPercent.NGN;
					break;
				case "USD":
					percent =
						this.fundingMeans === "Bank transfer"
							? fundPercent.HABARI
							: fundPercent.USD;
					break;

				default:
					percent = fundPercent.USD;
					break;
			}
			if (details) {
				this.fundPayload.amount = Number(details.amount);
				this.fundPayload.tokens = Number(details.tokens.toFixed(2));
				switch (this.fundOptions) {
					case "Fund with Fintech Apps":
						this.transactionFee =
							fundPercent.THEPEER * this.fundPayload.amount + 25;
						break;
					case "Fund with Fluidcoins":
						this.transactionFee =
							(this.fundPayload.amount / 100) * fundPercent.FLUIDCOINS * 100;
						break;
					case "Fund with bank transfer":
						const fee = percent * this.fundPayload?.amount;
						this.transactionFee = fee <= 2000 ? fee : 2000;
						break;

					default:
						this.transactionFee = percent * this.fundPayload?.amount;
						break;
				}
				const payload = {
					tokens: this.fundPayload.tokens,
					amount: this.fundPayload.amount,
					totalAmount: this.fundPayload.amount + this.transactionFee,
				};
				setItem("fundPayload", payload);
			}
		},

		async fundRequest() {
			this.loading = true;
			switch (this.fundOptions) {
				case "Bank or Card":
					// this.$emit("storeData", "fundConfirmationForm");
					this.flutterwaveFunding();
					break;
				case "Mobile Money":
					// this.$emit("storeData", "fundConfirmationForm");
					this.flutterwaveFunding();
					break;
				case "Fund with debit card":
					this.$emit("storeData", "fundConfirmationForm");
					// this.flutterwaveFunding();
					break;
				case "":
					this.$emit("storeData", "fundProvider");
					// this.flutterwaveFunding();
					break;
				case "Fund with bank transfer":
					this.$emit("storeData", "fundConfirmationForm");
					break;
				case "Fund with Fintech Apps":
					// this.thepeerFunding();
					this.$emit("storeData", "fundConfirmationForm");
					break;
				case "Crypto":
					this.cryptoFunding();
					break;
				case "USD Wallet":
					this.fundFromWallet();
					break;
				case "NGN Wallet":
					this.fundFromWallet();
					break;
				case "KES Wallet":
					this.fundFromWallet();
					break;

				default:
					break;
			}
		},

		async fundFromWallet() {
			const payload = {
				amount: this.fundPayload.amount,
				from: this.swapCurrency,
				to: this.currency,
			};
			try {
				const response = await this.convertWallet(payload);
				const { message } = response.data;
				this.loading = false;
				this.showToast({
					description: message,
					display: true,
					type: "success",
				});
				await this.getWalletBalance();
				this.$emit("close", "Fund Wallet");
			} catch (error) {
				this.loading = false;
			}
		},

		async flutterwaveFunding() {
			const data = {
				amount: this.fundPayload.amount,
				currency: this.currency,
				customer: {
					name: `${this.user.fname} ${this.user.lname}`,
					email: this.user.email,
				},

				customizations: this.customizations,
				meta: {
					// tokens: Number(
					// 	this.fundPayload.tokens || this.fundPayload.amount * 100
					// ),
					entity: "user",
				},

				paymentReference: "",
				redirect_url: window.location.href.includes("wallet")
					? window.location.origin + "/dashboard/wallet"
					: window.location.origin + "/dashboard/overview",

				// payment_options: ["card"],
			};
			try {
				const response = await api.fundWithProvider({
					data,
					type: "flutterwave",
				});
				window.location = response.data.data.link;
				this.loading = false;
			} catch (error) {
				responseHandler.handleError(error);
				this.loading = false;
			}
		},

		async thepeerFunding() {
			// eslint-disable-next-line no-undef
			const thePeerInstance = new ThePeer.directCharge({
				publicKey: `${process.env.VUE_APP_THE_PEER}`,
				amount: this.fundPayload.amount * 100,
				currency: this.currency,
				userReference: this.user.thepeer_reference,
				meta: {
					tokens: Number(this.fundPayload.tokens),
					entity: "user",
				},

				onError: function (error) {
					responseHandler.handleError(error);
				},

				onClose: () => {
					this.getWalletBalance();
					this.$emit("close", "Fund Wallet");
				},

				onSuccess: () => {
					this.getWalletBalance();
					this.$emit("close", "Fund Wallet");
				},
			});
			thePeerInstance.setup();
			thePeerInstance.open();
		},

		async cryptoFunding() {
			const tokens = this.fundPayload.amount;
			const data = {
				amount: tokens,
				metadata: {
					tokens: Number(tokens),
					entity: "user",
				},

				email: this.user.email,
				name: `${this.user.fname} ${this.user.lname}`,
				currency: "USD",
				redirect_url: window.location.href.includes("wallet")
					? "https://staging--dealroom.netlify.app" + "/dashboard/wallet"
					: "https://staging--dealroom.netlify.app" + "/dashboard/overview",
			};
			try {
				const response = await api.fundWithProvider({
					data,
					type: "triplea",
				});
				window.location = response.data.data.link;
				this.loading = false;
			} catch (error) {
				responseHandler.handleError(error);
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
