<template>
  <div class="tw-relative tw-flex tw-flex-col tw-justify-center">
    <label :for="label" class="tw-text-sm">
      {{ showLabel ? label : "" }}
    </label>
    <input
      :type="type"
      ref="numberInput"
      class="tw-text-xl tw-rounded tw-py-4 tw-px-5 focus:tw-border-primary"
      :class="{
        'tw-border-error': showError === true,
      }"
      v-model="numberData"
      @input="setInput"
      @keypress="isNumber($event)"
      @blur="validate"
      @keydown.enter="blurInput"
      :placeholder="placeHolder"
      :id="id"
      :name="name"
      :disabled="disabled"
      required
    />
    <p class="tw-absolute tw--top-4 tw-left-1 tw-text-error tw-text-xs">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";
import { isNumber, convertToLocale } from "@/utils/helpers.js";
export default {
  name: "NumberInput",
  emits: ["set", "valid", "validate"],
  props: {
    placeHolder: { type: String, default: () => "", required: true },
    showLabel: { type: Boolean, default: () => false },
    advancedMode: { type: Boolean, default: () => false },
    label: { type: String, default: () => "" },
    id: { type: String, default: () => "" },
    name: { type: String, default: () => "" },
    amount: { type: String, default: () => "" },
    amountRequired: { type: String, default: () => "" },
    minNumber: { type: Number, default: () => 0 },
    maxNumber: { type: Number, default: () => 0 },
    totalAmount: { type: Number, default: () => 0 },
    tradeType: { type: String, default: () => "" },
    fieldType: { type: String, default: () => "" },
    type: { type: String, default: () => "" },
    checkValidity: { type: Boolean, default: () => false },
    disabled: { type: Boolean, default: () => false },
  },
  data() {
    return {
      v$: useValidate(),
      numberData: "",
      intNumberData: "",
      errorMsg: "",
      showError: false,
    };
  },
  computed: {
    formatNumberData() {
      if (this.numberData === "NaN") {
        this.showError = false;
        this.errorMsg = "";
        this.numberData = "";
        this.intNumberData = "";
      }
      return this.convertToLocale(Number(this.intNumberData));
    },
  },
  validations() {
    return {
      amount: {
        required,
        minValue: minValue(Number(this.minNumber)),
        maxValue: maxValue(Number(this.maxNumber)),
      },
      amountRequired: {
        required,
        minValue: minValue(Number(this.minNumber)),
      },
      totalAmount: {
        maxValue: maxValue(Number(this.maxNumber)),
      },
    };
  },
  methods: {
    isNumber,
    convertToLocale,
    validate() {
      this.v$.$reset();
      switch (this.fieldType) {
        case "amount":
          this.v$.amount.$touch();
          if (this.totalAmount !== 0) this.v$.totalAmount.$touch();
          break;
        case "dollarAmount":
          if (this.advancedMode && this.tradeType === "Sell") {
            return this.$emit("valid", true);
          }
          this.v$.amount.$touch();
          if (this.totalAmount !== 0) this.v$.totalAmount.$touch();
          break;
        case "nairaAmount":
          this.v$.amount.$touch();
          if (this.totalAmount !== 0) this.v$.totalAmount.$touch();
          break;
        case "tokenAmount":
          if (this.advancedMode && this.tradeType === "Buy") {
            return this.$emit("valid", true);
          }
          this.v$.amount.$touch();
          if (this.totalAmount !== 0) this.v$.totalAmount.$touch();
          break;
        case "amountRequired":
          this.v$.amountRequired.$touch();
          if (this.totalAmount !== 0) this.v$.totalAmount.$touch();
          break;
        default:
          break;
      }
      if (this.v$.$errors.length === 0) {
        this.$emit("valid", true);
        return (this.showError = false), (this.errorMsg = "");
      } else {
        this.$emit("valid", false);
        switch (this.v$.$errors[0].$message) {
          case `The maximum value is ${this.maxNumber}`:
            if (this.name === "tokenAmount") {
              if (this.tradeType === "Buy")
                this.errorMsg = `Funds in your wallet can only buy ${this.maxNumber} tokens.`;
              if (this.tradeType === "Sell")
                this.errorMsg = `You only have ${this.maxNumber} tokens to sell.`;
            } else if (this.name === "dollarAmount") {
              this.errorMsg = `You only have $${this.maxNumber}.`;
            } else {
              this.errorMsg = this.v$.$errors[0].$message;
            }
            break;
          case `The minimum value allowed is ${this.minNumber}`:
            if (this.name === "tokenAmount") {
              this.errorMsg = `You can't buy less than ${this.minNumber} tokens.`;
            } else if (this.name === "dollarAmount") {
              this.errorMsg = `You can't withdraw less than $${this.minNumber}.`;
            } else if (this.name === "fundAmount") {
              this.errorMsg = `You can't fund less than $${this.minNumber}.`;
            } else {
              this.errorMsg = this.v$.$errors[0].$message;
            }
            break;
          default:
            this.errorMsg = this.v$.$errors[0].$message;
            break;
        }
        this.showError = true;
      }
    },
    setInput() {
      this.showError = false;
      this.errorMsg = "";
      this.$emit("set", this.intNumberData);
    },
    blurInput() {
      this.validate();
      setTimeout(() => {
        this.$refs.numberInput.blur();
      }, 500);
    },
  },
  watch: {
    formatNumberData(val) {
      this.numberData = val;
    },
    numberData(val) {
      this.intNumberData = val.split(",").join("");
    },
    amount(val) {
      this.numberData = this.convertToLocale(Number(val));
    },
    amountRequired(val) {
      this.numberData = this.convertToLocale(Number(val));
    },
    checkValidity(val) {
      if (val) {
        this.validate();
        this.$emit("validate");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
