<template>
	<FundModal
		title="Fund Wallet"
		:subTitle="fundSubTitle"
		:form="fundForm"
		:showBackButton="fundForm === 'SelectCurrencyForm' ? false : true"
		@close="$emit('close-modal', 'Fund Wallet')"
		@goBack="goBack"
		@mounted="refresh"
	>
		<template v-slot:content>
			<FundOptionsForm
				v-if="fundForm === 'fundOptionsForm'"
				class="tw-px-4 lg:tw-px-6"
				:isAllowed="isAllowed"
				:rate="exchangeRate"
				:provider="getProvider"
				@options="switchWithdrawForm"
			/>
			<SelectCurrencyForm
				v-if="fundForm === 'SelectCurrencyForm'"
				class="tw-px-4 md:tw-px-10"
				:fundOptions="fundOptions"
				@storeData="switchWithdrawForm"
			/>
			<DedicatedVirtualAccountForm
				v-if="fundForm === 'DedicatedVirtualAccountForm'"
				class="tw-px-4 md:tw-px-10"
				@close="$emit('close-modal', 'Fund Wallet')"
			/>
			<FundAmountForm
				v-if="fundForm === 'fundAmountForm'"
				class="tw-px-4 md:tw-px-10"
				:rate="exchangeRate"
				:fundOptions="fundOptions"
				@storeData="switchWithdrawForm"
			/>
			<CardOptionsForm
				v-if="fundForm === 'CardOptions'"
				class="tw-px-4 md:tw-px-10"
				@storeData="switchWithdrawForm"
			/>
			<FundConfirmationForm
				v-if="fundForm === 'fundConfirmationForm'"
				class="tw-px-4 lg:tw-px-10"
				:fundOptions="fundOptions"
				@storeData="switchWithdrawForm"
				@close="$emit('close-modal', 'Fund Wallet')"
			/>
			<SelectDebitCardForm
				v-if="fundForm === 'SelectDebitCardForm'"
				class="tw-px-4 lg:tw-px-10"
				:fundOptions="fundOptions"
				@storeData="switchWithdrawForm"
			/>
			<WalletList
				@wallet-select="fundFromWallet"
				@close="$emit('close-modal', 'Fund Wallet')"
				v-if="fundForm === 'walletList'"
			/>
		</template>
	</FundModal>
</template>

<script setup>
import FundModal from "@/components/general/sidemodal/SideModal";
import CardOptionsForm from "@/components/wallet/fund/CardOptionsForm";
import DedicatedVirtualAccountForm from "@/components/wallet/fund/DedicatedVirtualAccountForm";
import FundAmountForm from "@/components/wallet/fund/FundAmountForm";
import FundConfirmationForm from "@/components/wallet/fund/FundConfirmationForm";
import FundOptionsForm from "@/components/wallet/fund/FundOptionsForm";
import SelectCurrencyForm from "@/components/wallet/fund/SelectCurrencyForm";
import SelectDebitCardForm from "@/components/wallet/fund/SelectDebitCardForm.vue";
import WalletList from "@/components/wallet/fund/WalletList.vue";
import { useFundingProvider } from "@/composables/fundingProvider";
import { convertToLocale } from "@/utils/helpers";
import { getItem } from "@/utils/storage.js";
import { computed, provide, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["close-modal"]);

const { providerFunding } = useFundingProvider();

const store = useStore();

const fundForm = ref("fundOptionsForm");
const fundOptions = ref("");
const fundSubTitle = ref("");
const isAllowed = ref(false);
const withdrawFees = ref(0);
const swapCurrency = ref("");

const exchangeRate = computed(
	() => store.getters["organisationModule/exchangeRate"]
);
const virtualAccount = computed(
	() => store.getters["organisationModule/virtualAccount"]
);
const currency = computed(() => store.getters["organisationModule/currency"]);
const entityCard = computed(
	() => store.getters["organisationModule/entityCard"]
);
const fundMethod = computed(
	() => store.getters["organisationModule/fundMethod"]
);
const getProvider = computed(() => {
	let provider = null;
	switch (currency.value) {
		case "USD":
			provider = "flutterwave";
			break;
		case "GBP":
			provider = "flutterwave";
			break;
		case "EUR":
			provider = "flutterwave";
			break;
		case "NGN":
			provider =
				fundOptions.value === "Fund with bank transfer"
					? "squad"
					: "flutterwave";
			break;

		default:
			provider = "flutterwave";
			break;
	}
	return provider;
});
provide("provider", getProvider);
provide("swapCurrency", swapCurrency);

const fundFromWallet = (currency) => {
	swapCurrency.value = currency;
	switchWithdrawForm("walletList");
};

const switchWithdrawForm = async (form) => {
	const fundDetails = getItem("fundPayload");
	let payload;
	switch (form) {
		case "Fund with debit card":
			fundForm.value = "fundConfirmationForm";
			fundSubTitle.value = "Kindly confirm payment";
			fundOptions.value = "Fund with debit card";
			break;
		case "Bank or Card":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to fund?";
			fundOptions.value = "Bank or Card";
			break;
		case "Mobile Money":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to fund?";
			fundOptions.value = "Mobile Money";
			break;
		case "Fund with bank transfer":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to fund?";
			fundOptions.value = "Fund with bank transfer";
			break;
		// case "Fund with bank transfer":
		// 	fundForm.value = "fundConfirmationForm";
		// 	fundSubTitle.value = "Kindly confirm payment";
		// 	fundOptions.value = "Fund with bank transfer";
		// 	break;
		// case "selectCurrencyForm":
		// 	fundForm.value = "fundAmountForm";
		// 	fundSubTitle.value = "How much do you want to fund?";
		// 	break;
		case "fundConfirmationForm":
			payload = {
				currency: currency.value,
				provider: getProvider.value,
			};
			if (fundOptions.value === "Fund with bank transfer") {
				const response = await store.dispatch(
					"organisationModule/generateVirtualAccount",
					payload
				);
				if (response) {
					fundForm.value = "DedicatedVirtualAccountForm";
					fundSubTitle.value = `<p class="tw-text-gray-lightest3">Transfer a total amount of <span class='tw-text-primary tw-font-bold'>${
						currency.value
					} ${convertToLocale(
						fundDetails.totalAmount
					)}</span> to this account to fund your wallet.</p>`;
				}
				return;
			}
			if (
				virtualAccount.value.account_number &&
				fundMethod.value === "virtual"
			) {
				fundForm.value = "DedicatedVirtualAccountForm";
				fundSubTitle.value = `<p class="tw-text-gray-lightest3">Transfer a total amount of <span class='tw-text-primary tw-font-bold'>${
					currency.value
				} ${convertToLocale(
					fundDetails.totalAmount
				)}</span> to this account to fund your wallet.</p>`;
			}
			if (fundMethod.value === "fiat") {
				fundForm.value = "SelectDebitCardForm";
				fundSubTitle.value = `Select card to fund <span class='tw-text-primary tw-font-bold'>${
					currency.value
				} ${convertToLocale(fundDetails.totalAmount)}</span> with`;
			}
			break;
		case "Another wallet":
			fundForm.value = "walletList";
			fundSubTitle.value = "Where would you like to convert from?";
			break;
		case "walletList":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to convert?";
			fundOptions.value = `${swapCurrency.value} Wallet`;
			break;
		case "fundStripe":
			providerFunding(getProvider.value);
			break;
		case "Fund with Fintech Apps":
			fundForm.value = "fundConfirmationForm";
			fundSubTitle.value = "Kindly confirm payment";
			fundOptions.value = "Fund with Fintech Apps";
			break;
		case "Crypto":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to fund?";
			fundOptions.value = "Crypto";
			break;
		case "fundAmountForm":
			fundForm.value = "fundConfirmationForm";
			fundSubTitle.value = "Kindly confirm payment";
			break;
		// case "fundAmountForm":
		//   fundForm.value = "fundConfirmationForm";
		//   fundSubTitle.value = "Kindly confirm payment";
		//   break;

		default:
			break;
	}
};

const goBack = (form) => {
	const fundDetails = getItem("fundPayload");
	switch (form) {
		case "SelectCurrencyForm":
			fundForm.value = "fundOptionsForm";
			fundSubTitle.value = "How do you want to fund your wallet";
			break;
		// case "fundOptionsForm":
		//   fundForm.value = "fundAmountForm";
		//   fundSubTitle.value = "How much do you want to fund?";
		//   break;
		case "fundAmountForm":
			fundForm.value = "fundOptionsForm";
			fundSubTitle.value = "How do you want to fund your wallet?";
			break;
		case "DedicatedVirtualAccountForm":
			fundForm.value = "fundConfirmationForm";
			fundSubTitle.value = `<p class="tw-text-gray-lightest3">Transfer a total amount of <span class='tw-text-primary tw-font-bold'>${
				currency.value
			} ${convertToLocale(
				fundDetails.totalAmount
			)}</span> to this account to fund your wallet.</p>`;
			break;
		case "fundConfirmationForm":
			fundForm.value = "fundAmountForm";
			fundSubTitle.value = "How much do you want to fund?";
			break;
		case "SelectDebitCardForm":
			fundForm.value = "fundConfirmationForm";
			fundSubTitle.value = `<p class="tw-text-gray-lightest3">Transfer a total amount of <span class='tw-text-primary tw-font-bold'>${
				currency.value
			} ${convertToLocale(
				fundDetails.totalAmount
			)}</span> to this account to fund your wallet.</p>`;
			break;

		default:
			break;
	}
};

const refresh = () => {
	fundForm.value = "fundOptionsForm";
	fundSubTitle.value = "How do you want to fund your wallet?";
};
</script>

<style lang="scss" scoped></style>
