<template>
  <div>
    <div v-if="!loading">
      <div v-for="(card, index) in entityCard" :key="index" class="tw-mt-6">
        <div
          class="tw-relative tw-flex tw-bg-blue-lightest1 tw-border tw-border-white tw-rounded tw-cursor-pointer hover:tw-border-primary tw-py-4 tw-px-8"
          @click="fundWithStoredCard(card.token)"
        >
          <div class="tw-mr-4">
            <img
              v-if="card.type === 'MASTERCARD'"
              src="@/assets/img/mastercard.svg"
              alt="mastercard"
            />
            <img
              v-else-if="card.type === 'VISA'"
              src="@/assets/img/visa.svg"
              alt="visa card"
            />
            <img v-else src="@/assets/img/card-icon.svg" alt="card icon" />
          </div>
          <div>
            <p class="tw-text-secondary tw-font-bold">
              **** {{ card.last_four }}
            </p>
            <p class="tw-text-purple-light tw-text-sm">
              Expires {{ card.expiry }}
            </p>
          </div>
          <img
            class="tw-block tw-ml-auto tw-top-8 tw-right-8 tw-w-2"
            src="@/assets/img/caret-right-black.svg"
            alt="caret"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-justify-between tw-items-center tw-bg-blue-lightest1 tw-rounded-lg tw-py-4 tw-px-8 tw-mt-12"
      >
        <div>
          <h3 class="tw-text-sm tw-font-bold">Remember my new card details</h3>
          <p class="tw-text-sm tw-text-purple-light">
            You won't have to add it again
          </p>
        </div>
        <ToggleButton @valueSelected="toggleMode" />
      </div>
      <div
        class="tw-bg-blue-lightest1 tw-border tw-border-white tw-rounded hover:tw-border-primary tw-cursor-pointer tw-py-4 tw-px-8 tw-mt-4"
        @click="flutterwaveFunding"
      >
        <div class="tw-relative tw-flex tw-justify-between tw-items-center">
          <h3 class="tw-font-bold tw-text-secondary">Use a New Card</h3>
          <img
            class="tw-absolute tw-top-1 tw-right-0 tw-w-2"
            src="@/assets/img/caret-right-black.svg"
            alt="caret"
          />
        </div>
        <p class="tw-text-purple-light tw-text-sm tw-mt-4">
          You will be redirected to {{ capitalize(provider) }} portal where you
          will be required to enter your card details and authorize this
          transaction.
        </p>
      </div>
      <p class="tw-text-gray tw-text-sm tw-text-center tw-mt-8">
        Transactions are secured by {{ capitalize(provider) }}
      </p>
    </div>
    <SmallLoader v-if="loading" />
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import ToggleButton from "@/components/general/ToggleButton";
  import responseHandler from "@/utils/responseHandler.js";
  import api from "@/utils/api.js";
  import { getItem } from "@/utils/storage.js";
  import { capitalize } from "@/utils/helpers";
  // import { useFundingProvider } from "@/composables/fundingProvider";

  export default {
    name: "SelectDebitCardForm",

    components: {
      ToggleButton,
    },

    props: {
      fundOptions: { type: String, default: () => "", required: true },
    },

    data() {
      return {
        user: getItem("org_details"),
        fundPayload: getItem("fundPayload"),
        addCard: false,
        loading: false,
      };
    },
    inject: ["provider"],

    computed: {
      ...mapState({
        currency: (state) => state.organisationModule.currency,
        entityCard: (state) => state.organisationModule.entityCard,
      }),
    },

    mounted() {
      this.getEntityCard();
      this.fundPayload = getItem("fundPayload");
    },

    methods: {
      capitalize,
      ...mapActions("organisationModule", ["getEntityCard"]),
      // async fundRequest() {
      //   switch (this.fundOptions) {
      //     case "new card":
      //       this.flutterwaveFunding();
      //       break;
      //     case "stored card":
      //       this.fundWithStoredCard();
      //       break;

      //     default:
      //       break;
      //   }
      // },

      async flutterwaveFunding() {
        this.loading = true;
        const data = {
          amount: this.fundPayload.amount,
          currency: this.currency,
          customer: {
            name: `${this.user.name}`,
            email: this.user.email,
          },

          customizations: this.customizations,
          meta: {
            tokens: Number(this.fundPayload.tokens),
            entity: "user",
            add_card: this.addCard,
          },

          paymentReference: "",
          redirect_url: window.location.origin + window.location.pathname,

          payment_options: ["card"],
        };
        try {
          const response = await api.fundWithProvider({
            data,
            type: this.provider,
          });
          window.location =
            this.provider === "flutterwave"
              ? response.data.data.link
              : response.data.data.url;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          responseHandler.handleError(error);
        }
      },

      async fundWithStoredCard(token) {
        this.loading = true;
        const data = {
          amount: this.fundPayload.amount,
          currency: this.currency,
          token,
          customer: {
            name: `${this.user.fname} ${this.user.lname}`,
            email: this.user.email,
          },

          customizations: this.customizations,
          meta: {
            tokens: Number(this.fundPayload.tokens),
            entity: "user",
          },

          paymentReference: "",
          redirect_url: window.location.origin + window.location.pathname,

          payment_options: ["card"],
        };
        try {
          const response = await api.fundWithProvider({
            data,
            type: this.provider,
          });
          window.location = response.data.data.link;
          this.loading = false;
        } catch (error) {
          responseHandler.handleError(error);
          this.loading = false;
        }
      },

      toggleMode(val) {
        this.addCard = val;
      },
    },
  };
</script>
