<!-- eslint-disable vue/html-indent -->
<template>
	<div>
		<div
			class="md:tw-flex md:tw-items-start md:tw-justify-between tw-border tw-border-gray-dark hover:tw-border-primary tw-rounded tw-cursor-pointer tw-p-3 tw-mt-4"
			@click="selected"
		>
			<div class="tw-w-2/3">
				<div class="tw-flex tw-items-center tw-gap-x-1">
					<div
						class="tw-w-10 tw-h-10 tw-rounded-full tw-bg-gray-light tw-flex tw-items-center tw-justify-center"
					>
						<img
							v-if="
								type === 'Fund with debit card' ||
								type === 'Another wallet' ||
								type === 'Mobile Money' ||
								type === 'Fund with bank transfer'
							"
							src="@/assets/img/card-icon.svg"
							alt="bank"
						/>
						<img
							v-if="type === 'Pay with cash'"
							src="@/assets/img/cash.svg"
							alt="bank"
						/>
						<img
							v-if="type === 'Crypto' || type === 'Pay with crypto'"
							src="@/assets/img/crypto-icon.svg"
							alt="bank"
						/>
						<img
							v-if="type === 'Bank or Card'"
							src="@/assets/img/bank-icon.svg"
							alt="bank"
						/>
					</div>
					<h3
						class="!tw-font-CircularStd-medium tw-font-medium tw-text-secondary tw-text-base"
					>
						{{ type }}
					</h3>
				</div>
				<div
					class="tw-flex tw-text-xs tw-font-thin tw-text-purple-light tw-ml-10"
				>
					<p>
						<span v-if="type !== 'Another wallet'">Fee: </span>
						<span
							v-if="type === 'Another wallet'"
							class="tw-text-secondary tw-text-sm"
						>
							Currency Conversion
						</span>
						<span
							v-if="
								type === 'Bank or Card' ||
								type === 'Pay with cash' ||
								type === 'Mobile Money'
							"
							class="tw-text-secondary tw-text-sm"
						>
							{{
								(fundPercent.NGN * 100).toFixed(1) +
								"%" +
								" - " +
								fundPercent.USD * 100
							}}%
						</span>
						<span
							v-if="type === 'Fund with bank transfer'"
							class="tw-text-secondary tw-text-sm"
						>
							{{ fundPercent.HABARI * 100 }}%
						</span>
						<span
							v-if="type === 'Crypto' || type === 'Pay with crypto'"
							class="tw-text-secondary tw-text-sm"
						>
							{{ fundPercent.FLUIDCOINS * 100 }}%
						</span>
					</p>
				</div>
				<div v-if="type === 'Crypto'" class="tw-flex tw-ml-10 tw-mt-2">
					<img
						class="tw-w-4 tw-mr-2"
						src="@/assets/img/fluidcoin.svg"
						alt="bank"
					/>
					<p class="tw-text-xs tw-font-thin tw-text-gray">
						Powered by Fluidcoins
					</p>
				</div>
				<div
					v-if="
						type === 'Another wallet' ||
						(type === 'Fund with bank transfer' && currency === 'USD')
					"
					class="tw-flex tw-ml-10 tw-mt-2"
				>
					<img
						class="tw-w-4 tw-mr-2"
						src="@/assets/img/getequity.svg"
						alt="GetEquity"
					/>
					<p class="tw-text-xs tw-font-thin tw-text-gray">
						Powered by GetEquity
					</p>
				</div>
				<div
					v-if="type === 'Fund with bank transfer' && currency === 'NGN'"
					class="tw-flex tw-ml-10 tw-mt-2"
				>
					<img
						class="tw-w-4 tw-mr-2 tw-object-contain"
						src="@/assets/img/squad.jpeg"
						alt="GetEquity"
					/>
					<p class="tw-text-xs tw-font-thin tw-text-gray">
						Powered by HabariPay
					</p>
				</div>
				<div v-if="type === 'Bank or Card'" class="tw-flex tw-ml-10 tw-mt-2">
					<img
						v-if="provider === 'flutterwave'"
						class="tw-w-4 tw-mr-2"
						src="@/assets/img/flutterwave-icon.svg"
						:alt="provider + ' logo'"
					/>
					<img
						v-else
						class="tw-w-5 tw-mr-2"
						src="@/assets/img/stripe.jpeg"
						:alt="provider + ' logo'"
					/>
					<p class="tw-text-xs tw-font-thin tw-text-gray">
						Powered by {{ capitalize(provider) }}
					</p>
				</div>
			</div>
			<div
				class="tw-flex tw-items-center tw-bg-gray-light tw-rounded-full tw-px-2 tw-py-0.5 tw-mt-3 md:tw-mt-1 tw-w-max"
			>
				<img class="tw-mr-1" src="@/assets/img/clock-icon.svg" alt="clock" />
				<p
					v-if="
						type === 'Pay with Crypto' ||
						type === 'USD Debit/Credit Card' ||
						type === 'Pay with crypto' ||
						type === 'Crypto'
					"
					class="tw-text-xs tw-font-thin tw-min-w-[45px] tw-text-gray"
				>
					1 day
				</p>
				<p
					v-if="
						type !== 'Pay with Crypto' &&
						type !== 'USD Debit/Credit Card' &&
						type !== 'Another wallet' &&
						type !== 'Pay with crypto' &&
						type !== 'Crypto'
					"
					class="tw-text-xs tw-font-thin tw-min-w-[45px] tw-text-gray"
				>
					15 mins
				</p>
				<p
					v-if="type === 'Another wallet'"
					class="tw-text-xs tw-font-thin tw-min-w-[45px] tw-text-gray"
				>
					1 minute
				</p>
			</div>
			<!-- <div
        v-if="type !== 'Pay with Crypto' && type !== 'USD Debit/Credit Card'"
        class="tw-w-20 tw-flex tw-items-center tw-bg-gray-light tw-rounded-full tw-px-2 tw-py-0.5 tw-ml-9 tw-mt-3 md:tw-mt-1"
      >
        <img class="tw-mr-1" src="@/assets/img/clock-icon.svg" alt="clock" />
        <p class="tw-text-xs tw-font-thin">15mins</p>
      </div> -->
		</div>
	</div>
</template>

<script setup>
import { capitalize } from "@/utils/helpers";
import fundPercent from "@/utils/js/fundPercent.js";
import { computed, inject } from "vue";
import { useStore } from "vuex";

const store = useStore();

const emits = defineEmits(["clicked"]);
const props = defineProps({
	type: {
		type: String,
		required: true,
	},
});
const type = computed(() => props.type);
const currency = computed(() => store.getters["organisationModule/currency"]);
const provider = inject("provider");
const selected = () => {
	emits("clicked", type.value);
};
</script>

<style lang="scss" scoped></style>
